import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Form from '../components/form'
import Helmet from 'react-helmet'
import { Container } from 'reactstrap'

const About = () => (
  <Layout>
  <Helmet
    title="About Us - Our Window Cleaning Method | Supreme Cleaning"
    meta={[
      { name: 'description', content: 'Supreme cleaning specialise in a professional, friendly domestic window cleaning service using nothing but pure water.' },
    ]}
  />
<section className="py-5">
      <Container>
        <h1 className="text-center display-3 mb-3">A Bit About Us</h1>
          <p>Supreme Cleaning was established in 2010 by Wesley Baldwin, and is based in Essex. We specialise in providing a high standard window cleaning service throughout Essex and London.</p>
          <p>As a family we have been cleaning windows for over 30 years and have developed an excellent reputation for quality service in Essex and London areas.</p>
          <p className="mb-5">We strive to provide all of our customers with a reliable window cleaning service both in the domestic and commercial sectors.</p>
      </Container>
    </section>
    <section className="background-blue py-5 text-light">
      <Container>
        <h2 id="cleaning-method" className="display-4 text-center mb-4">Our Window Cleaning Method</h2>
        <p>Supreme Cleaning are proud of the high standard of service that they offer, which is why our customers happily recommend us.</p>
        <p>We achieve such high quality by using pure water technology. Unlike traditional window cleaning methods, this system leaves no streaks or residue on your windows when it dries, and gives you a crystal clear view every time.</p>
        <p>Our high-reach water-fed pole system also allows us to clean hard to reach windows up to 50ft high.</p>
      </Container>
    </section>
    <section className="py-5">
      <Container>
        <h2 className="display-4 mb-4 text-center">Areas Covered</h2>
        <p>Our Domestic and Commercial cleaning services are provided to Essex and London. However, for commercial jobs we do travel further afield. If you would like to see whether we cover your local area, please <Link to="/contact">contact us</Link>.</p>
<iframe title="areas covered" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19602.17777064312!2d0.5188782366381485!3d51.7294350943717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8e8f0497de9df%3A0x4a6cb8e057beb9d1!2sHammonds+Rd%2C+Little+Baddow%2C+Chelmsford!5e0!3m2!1sen!2suk!4v1564837327440!5m2!1sen!2suk" width="100%" height="300" frameBorder="0" style={{border:0}} allowfullscreen></iframe>
      </Container>
    </section>
    <section className="py-5">
      <Container>
        <h2 className="display-4 mb-4 text-center">Get in touch</h2>
        <Form name="aboutForm" />
      </Container>
    </section>
  </Layout>
)

export default About
